import * as next from "next"
import { destroyCookie, parseCookies, setCookie } from "nookies"

const MAX_AGE = 60 * 60 * 24 * 30 // 30 days
export const YEAR = 60 * 60 * 24 * 356 // 365 days

export type CookieReqContext =
  | Pick<next.NextPageContext, "req">
  | { req: next.NextApiRequest }
  | null
  | undefined

export type CookieResContext =
  | Pick<next.NextPageContext, "res">
  | { res: next.NextApiResponse }
  | null
  | undefined

export function saveCookie(
  ctx: CookieResContext,
  key: string,
  value: string,
  ttl?: number,
  sameSite?: string,
) {
  setCookie(ctx, key, value, {
    maxAge: ttl || MAX_AGE,
    expires: ttl
      ? new Date(Date.now() + ttl * 1000)
      : new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: process.env.NODE_ENV === "production" || sameSite === "none",
    path: "/",
    sameSite: sameSite ?? "lax",
  })
}

export function saveUtmCookie(
  ctx: CookieResContext,
  key: string,
  value: string,
  ttl?: number,
) {
  setCookie(ctx, key, value, {
    maxAge: ttl || MAX_AGE,
    expires: ttl
      ? new Date(Date.now() + ttl * 1000)
      : new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: true,
    path: "/",
    sameSite: "none",
  })
}

export function deleteCookie(ctx: CookieResContext, key: string) {
  destroyCookie(ctx, key, {
    maxAge: -1,
    path: "/",
  })
}

export function getCookie(ctx: CookieReqContext, key: string): string {
  const cookies = parseCookies(ctx)
  return cookies[key]
}

export const COOKIES_CONSTANT = {
  TOKEN_NAME: "token",
  USER_ID: "user_id",
  ANALYTICS_USER_ID: "ajs_user_id",
  SESSION_TOKEN: "sessiontoken",
  USER_COUNTRY: "user_country",
  IP_COUNTRY_KEY: "IP_COUNTRY_KEY",
  LIY_EXAM_FORM: "LIY_EXAM_FORM",
}
