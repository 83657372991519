import { FunctionComponent, useEffect, useState } from "react"
import debounce from "lodash/throttle"
import Button from "components/LSRevamp/common/Button"
import { IIvrMessage } from "components/Login/index"

interface Props {
  onClick: () => void
  ivrMsg: IIvrMessage
  setIvrMsg: (value: IIvrMessage) => void
}
const OtpOnCall: FunctionComponent<Props> = ({
  onClick,
  ivrMsg,
  setIvrMsg,
}) => {
  const [timer, setTimer] = useState(15)
  const [attempts, setAttempts] = useState<number>(1)
  useEffect(() => {
    if (shouldEnableButton(attempts) && ivrMsg.type === "success") {
      setTimer(15)
      setAttempts(attempts + 1)
    }
  }, [ivrMsg])
  useEffect(() => {
    return () => {
      setAttempts(1)
      setIvrMsg({
        type: "default",
        message: "",
      })
    }
  }, [])
  useEffect(() => {
    if (ivrMsg.type === "success" || ivrMsg.type === "default") {
      const timerRef = setInterval(() => {
        setTimer((prevState) => {
          if (prevState <= 0) {
            clearInterval(timerRef)
            return 0
          }
          return prevState - 1
        })
      }, 1000)
      return () => clearInterval(timerRef)
    }
  }, [ivrMsg])

  const buttonClick = debounce(onClick, 500, {
    leading: true,
  })
  const getVerb = () => {
    return ivrMsg.type === "default" || ivrMsg.type === "error"
      ? "Get"
      : "Resend"
  }
  const shouldEnableButton = (attempts: number) => {
    return attempts < 2
  }
  if (timer !== 0 && shouldEnableButton(attempts))
    return (
      <div className="text-primary cursor-pointer">
        {getVerb()} OTP on call in: {timer} sec
      </div>
    )
  return (
    <div className="w-full flex-col items-center">
      {shouldEnableButton(attempts) && (
        <Button
          trackingEvent={() => {
            buttonClick()
          }}
          backgroundColor="cursor-pointer"
          text={`${getVerb()} OTP on Call`}
          color="text-primary py-1"
          width="w-full"
        >
          {getVerb()} OTP on Call
        </Button>
      )}
      {ivrMsg.message && (
        <div
          className={`${
            ivrMsg.type == "success"
              ? "text-[12px] lfc-text-success-500"
              : "text-[12px] lfc-text-error-500"
          } px-2`}
        >
          {ivrMsg.message}
        </div>
      )}
    </div>
  )
}
export default OtpOnCall
