import CircularProgress from "@mui/material/CircularProgress"
import { PrimaryButton } from "components/Inputs/Button"
import { CountryCode, countryData } from "DataMapper/CountryCode"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import OtpOnCall from "components/Login/OtpOnCall"
import { IIvrMessage } from "components/Login/index"

interface Props {
  otp: Array<any>
  phone: string
  otpText: string
  otpErrMsg: string
  otpSuccessMsg: string
  country: CountryCode
  changeView: (view: string) => void
  submitOtp: () => Promise<void>
  resendOtp: () => void
  handleChange: (val: Array<any>) => void
  ivrCallRequest: () => void
  ivrMsg: IIvrMessage
  setIvrMsg: (value: IIvrMessage) => void
}

const EnterOtp: FunctionComponent<Props> = ({
  otp,
  phone,
  otpText,
  otpErrMsg,
  otpSuccessMsg,
  country,
  changeView,
  submitOtp,
  resendOtp,
  handleChange,
  ivrCallRequest,
  ivrMsg,
  setIvrMsg,
}) => {
  const [resendOtpCount, setResendOtpCount] = useState<number>(30)
  const resendOtpCountRef = useRef(resendOtpCount)
  const [submitLock, setSubmitLock] = useState(false)

  useEffect(() => {
    const otpInterval = setInterval(() => {
      if (resendOtpCountRef.current < 1) {
        clearInterval(otpInterval)
      } else {
        resendOtpCountRef.current -= 1
        setResendOtpCount(resendOtpCountRef.current)
      }
    }, 1000)
    return () => {
      clearInterval(otpInterval)
    }
  }, [])

  const getOtpMsg = () => {
    if (resendOtpCount) {
      return (
        <p className="text-lightgray-400">
          Resend OTP in&nbsp;
          <span className="text-black">
            00:{resendOtpCount < 10 ? `0${resendOtpCount}` : resendOtpCount}
          </span>
        </p>
      )
    } else if (otpSuccessMsg) {
      return <p className="font-bold text-green-100">{otpSuccessMsg}</p>
    } else {
      return (
        <p
          onClick={resendOtp}
          className="text-primary font-bold cursor-pointer"
        >
          Resend OTP
        </p>
      )
    }
  }

  const otpRef = useRef<any>([])

  useEffect(() => {
    otpRef.current[0].focus()
  }, [])

  useEffect(() => {
    if (otp?.filter((n) => n)?.length === 6) {
      submitAndUnsetLock()
    }
  }, [otp])

  const handleOTP = (event: any, index: number) => {
    const newOTP = [...otp]
    const value = event.target.value
    if (!isNaN(value) && value.length <= 6 - index) {
      if (value.length === 1) {
        newOTP[index] = value
        if (index < 5) {
          otpRef.current[index + 1].focus()
        }
      } else if (value.length > 1 && value.length <= 6) {
        let offset = index
        while (offset < index + value.length) {
          newOTP[offset] = value[offset - index]
          if (offset + 1 <= 5) {
            otpRef.current[offset + 1].focus()
          }
          offset += 1
        }
      }
      handleChange(newOTP)
    }
  }

  const submitAndUnsetLock = () => {
    if (!submitLock) {
      setSubmitLock(true)
      submitOtp().finally(() => setSubmitLock(false))
    }
  }

  const handleDelete = (event: any, index: number) => {
    if (event.keyCode === 8) {
      const newOTP = [...otp]
      newOTP[index] = ""
      handleChange(newOTP)
      if (index > 0) {
        otpRef.current[index - 1].focus()
      }
    }
    if (
      event.key === "Enter" &&
      otp?.filter((n) => n)?.length === 6 &&
      !submitLock
    ) {
      submitAndUnsetLock()
    }
  }

  const checkOTPBoxColor = () => {
    if (otp?.filter((n) => n)?.length === 6 && otpErrMsg.length > 0)
      return "border-red-600"
    else if (otp?.filter((n) => n)?.length === 6 && otpSuccessMsg.length > 0)
      return "border-green-300"
    else return "border-lightgray-1200"
  }

  return (
    <>
      <div className="text-lightgray-400 text-sm pb-4 w-full justify-between flex">
        <p className="font-bold text-black">
          OTP sent to{" "}
          <strong className="text-black">
            +{`${countryData[country as CountryCode].code} ${phone}`}
          </strong>{" "}
        </p>
        <button
          className="text-primary font-bold cursor-pointer"
          onClick={() => changeView("phone")}
        >
          Change
        </button>
      </div>
      <div className="flex w-full pb-4 justify-between">
        <div className="flex">
          {" "}
          <p className="text-xs mr-2">Enter OTP</p>
          {otp?.filter((n) => n)?.length === 6 && otpErrMsg.length === 0 && (
            <CircularProgress size={14} />
          )}
        </div>
        <div className="flex justify-end text-xs">{getOtpMsg()}</div>
      </div>
      <div className="flex items-center w-full justify-between mb-4">
        {[...Array(6)].map((_i, index) => {
          return (
            <input
              className={`md:text-lg text-base md:w-12 md:h-12 w-10 h-10 text-center p-0.5 ${
                index < 5 ? "mr-1" : "mr-0"
              } ${
                index > 0 ? "ml-1" : "ml-0"
              } rounded border  ${checkOTPBoxColor()}`}
              key={index}
              type="number"
              placeholder=""
              value={otp[index]}
              onChange={(e) => handleOTP(e, index)}
              ref={(el) => (otpRef.current[index] = el)}
              onKeyDown={(event) =>
                (event.key === "e" && event.preventDefault()) ||
                handleDelete(event, index)
              }
            />
          )
        })}
      </div>
      {otpErrMsg && otp?.filter((n) => n)?.length === 6 && (
        <p className="text-sm py-1 w-full text-red-600">{otpErrMsg}</p>
      )}
      <PrimaryButton
        py="py-2 md:py-3"
        disabled={otp?.filter((n) => n)?.length < 6 || submitLock}
        className="w-full mt-4"
        ctaText={otpText}
        onClick={() => submitAndUnsetLock()}
      />
      {country === CountryCode.INDIA && (
        <div className="pt-4">
          <OtpOnCall
            onClick={ivrCallRequest}
            ivrMsg={ivrMsg}
            setIvrMsg={setIvrMsg}
          />
        </div>
      )}
    </>
  )
}

export default EnterOtp
