import {
  MOCK_TEST_TYPE,
  mockTestCategory,
} from "DataMapper/IeltsProfile/MockTest"
import { getIeltsMockTestUrl } from "utils/url_utils"

export const navRoutes = {
  HOMEPAGE: "/",
  VN_DASHBOARD: "/profile/dashboard",
  HOMEPAGECOPY: "/home",
  WEBFLOW_PROGRESSIVE_FORM: "/webflow_progressive_form",
  ABOUT_US: "/about-us",
  PRIVACY_PAGE: "/privacy",
  TERM_AND_CONDITION: "/terms",
  REFUND_POLICY: "/refund-policy",
  MENTORS: "/mentors",
  COUNSELLORS: "/counsellors",
  SCHOLARSHIP: "/scholarship-finder",
  SCHOALRSHIP_APPLICATION: "/scholarship-finder",
  SCHOALRSHIP_FAQ: "/scholarship-finder",
  FIND_UNIVERSITY: "/college-list",
  USA_MENTORS: "/connect-with-mentors",
  USA_MENTOR_VIDEOS: "/mentor-videos",
  USA_MENTOR_VIDEOS_VIDEO: "/mentor-videos/[video]",
  COUNTRY_COLLEGE: "/[country]/[college]",
  COUNTRY_COLLEGE_ALLCOURSES: "/[country]/[college]/all-courses",
  COUNTRY_COLLEGE_COURSE: "/[country]/[college]/[course]",
  IELTS_PAGE: "/ielts-free-online-coaching",
  NEW_MASTERCLASS_LANDING_PAGE: "/ielts-masterclass",
  IELTS_CRASH_COURSE: "/ielts-preparation-online/crash_course",
  IELTS_FREE_CRASH_COURSE: "/ielts-preparation-online/free_crash_course",
  IELTS_AI_EVALUTION: "/ielts-preparation-online/smartTests",
  IELTS_ALL_BATCHES: "/ielts-preparation-online/crash_course/all_batches",
  IELTS_BATCHES_PAGE: "/ielts-preparation-online/crash_course/ielts_batches",
  IELTS_FOUNDATION_BATCH:
    "/ielts-preparation-online/crash_course/all_batches?batch=foundation",
  IELTS_PLUS_BATCH:
    "/ielts-preparation-online/crash_course/all_batches?batch=plus",
  IELTS_PREMIUM_BATCH:
    "/ielts-preparation-online/crash_course/all_batches?batch=premium",
  IELTS_JOIN_WAITLIST: "/ielts-preparation-online/crash_course/join_waitlist",
  IELTS_BATCH_DETAILS:
    "/ielts-preparation-online/crash_course/[location]/[batchId]",
  PROFILE: "/profile",
  PROFILE_MASTERCLASS: "/profile?flowType=ielts&section=ieltsMasterclass",
  EVENTS: "/events",
  EVENTS_DETAILS: "/events/[eventId]",
  BOOK_IELTS_DEMO_FLOW:
    "/ielts-preparation-online/ielts-elite-demo/user-details-form",
  BOOK_IELTS_DEMO_FORM_MENA:
    "/ielts-preparation-online/user-details-form?form_version=2",
  RESCHEDULE_IELTS_DEMO: "/ielts-preparation-online/ielts-elite-demo",
  // Login Based URLs
  LOGIN_IELTS: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile?flowType=ielts&section=ieltsPlus",
  )}`,
  LOGIN_IELTS_MASTERCLASS: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile?flowType=ielts&section=ieltsMasterclass",
  )}`,
  LOGIN_IELTS_PRO: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile?flowType=ielts",
  )}`,
  LOGIN_COUNSELLING: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile?flowType=counselling",
  )}`,
  LOGIN_PROFILE: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile/user_acquried",
  )}`,

  POSTLOGIN_COUNSELLING: `/profile`,
  POSTLOGIN_IETLS_MASTERCLASS: `/profile/ielts_plus/overview?flowType=ielts`,
  POSTLOGIN_IELTS_PRO: `/profile/ielts_plus/overview?flowType=ielts`,
  REDIRECTION_IELTS_COMMUNITY: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile/join_ielts_community?redirect=/ielts-free-online-coaching&community=https://t.me/LeapScholar_IELTS_prep",
  )}`,
  USER_ACQUIRED: "/profile/counselling/user_acquried",
  LEAD_CAPTURE: "/profile/counselling/lead_capture",
  CALL_SCHEDULED: "/profile/counselling/call_scheduled",
  CALL_DONE: "/profile/counselling/call_done",
  PAYMENT_DONE: "/profile/counselling/payment_done",
  REDIRECTION_COMMUNITY: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile/join_ielts_community?redirect=/&community=https://t.me/joinchat/TvPaO3upIMaYMHJd",
  )}`,

  APPLY_FOR_SCHOLARSHIP: "/profile/apply-for-scholarship",
  IELTS_REGISTRATION_FORM: "/profile/ielts_pro_registration",
  MASTERCLASS_REGISTRATION_FORM: "/profile/masterclass_registration",
  SINGLE_DOCUMENT_UPLOAD_FORM: "/profile/single_document_upload",
  IELTS_STUDENT_DASHBOARD: "/test-prep/dashboard",
  MEA_IELTS_STUDENT_DASHBOARD: "/profile/ielts-dashboard",
  IELTS_PLUS: "/profile/ielts_plus/[screen]",
  IELTS_NOTICE_BOARD: "/profile/ielts_plus/notice_board",
  IELTS_CLASS_RECORDINGS: "/test-prep/dashboard/video-course",
  IELTS_STUDY_MATERIAL: "/test-prep/dashboard/study-material",
  IELTS_SPEAKING_CLUB: "/profile/ielts_plus/speaking_club",
  IELTS_CLASS_LIST: "/profile/ielts_plus/class_list",
  IELTS_MOCK_TEST: "/profile/ielts_plus/mock_test",
  IELTS_TEST_PREP_MOCK_TEST: "/profile/ielts/mocktest",
  IELTS_CERTIFICATES: "/profile/ielts_plus/certificate",
  PROFILE_SCHOLARSHIP: "/profile/scholarship",
  REGISTER_FOR_FINANCIAL_COUNSELLING:
    "/profile/register_for_financial_counselling",
  REGISTER_FOR_IELTS: "/profile/register_for_ielts",
  IELTS_ESSAY_EVALUATION: "/profile/ielts_plus/essay_evaluation",
  CAREERS_PAGE: "https://leapfinance.freshteam.com/jobs",
  IELTS_TO_COUNSELLING_NUDGE: "/counselling-nudge",
  LOGIN_IELTS_VIA_NAV: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile?flowType=ielts&type=navbar",
  )}`,
  LOGIN_COUNSELLING_VIA_NAV: `/api/login?callbackUrl=${encodeURIComponent(
    "/profile?flowType=counselling&type=navbar",
  )}`,
  MEETING_FINDER: "/meeting-finder",
  LIVE_CALENDAR_FINDER: "/live-calendar-finder",
  CRACK_IELTS_FAST_TRACK:
    "https://crackielts.leapscholar.com/ls-ielts-fasttrack",
  FAST_TRACK_LANDING_PAGE: "/ielts-preparation-online/fast_track",
  FAST_TRACK_VIDEO: "/profile/fast_track_video",
  FAST_TRACK_FORM: "/profile/fast_track_registration",
  FAST_TRACK: "/profile/ielts_plus/fast_track",
  PLUS_UPCOMING_CLASS: "/profile/ielts_plus/plus_class",
  PLUS_COMPLETE_CLASS: "/profile/ielts_plus/plus_complete",

  //new paths for counselling flow - start
  HOME: "/profile/counselling",
  COUNSELLING_OVERVIEW: "/profile/counselling/overview",
  COUNSELLING_MY_UNIVERSITIES: "/profile/counselling/my_universities",
  COUNSELLING_DOCUMENT_DETAILS: "/profile/counselling/document_details",
  COUNSELLING_NOTICE_BOARD: "/profile/counselling/notice_board",
  COUNSELLING_UPCOMING_APPOINTMENTS:
    "/profile/counselling/upcoming_appointments",
  COUNSELLING_FINANCIAL_ASSISTANCE: "/profile/counselling/financial_assistance",
  COUNSELLING_VISA_ASSISTANCE: "/profile/counselling/visa_assistance",
  USER_BOOKMARKS_SCREEN: "/profile/counselling/bookmarks",
  //new path for counselling floe - end
  TELEGRAM_FAST_TRACK: "https://t.me/joinchat/P6eGFG2AHWdmNWFl",
  OFFER_DETAILS: "/profile/counselling/offer",
  MASTERCLASS_MOCK_TEST: `/profile/ielts_plus/mock_test?${MOCK_TEST_TYPE}=${mockTestCategory.masterclass_mock_test}`,
  MOCK_TEST_PRODUCT: "https://pages.razorpay.com/ielts-mock-tests",
  CHAT: "/profile/chat",
  INITIAL_SHORTLIST: "/profile/counselling/initial-shortlist",
  IELTS_REWARDS: "/profile/ielts/rewards",
  MASTERCLASS_LIST: "/profile/ielts_plus/masterclass_list",
  PROFILE_NAV: "/profile?type=navbar",
  REPLAYS_VIDEO_LIST: "/profile/replays",
  EXTRA_CLASS_LIST: "/profile/ielts_plus/extraclass_list",
  IELTS_QUIZ: "/profile/ielts_quiz",
  IELTS_COMPLETE_QUIZ: "/profile/ielts_quiz?section=complete",
  BOOK_IELTS_TEST: "/profile/counselling/book_ielts_exam",
  MASTERCLASS_TYPE: "/ielts-preparation-online/masterclass",
  BEGINNER_MASTERCLASS: "/ielts-preparation-online/masterclass?type=beginner",
  CLASSIC_MASTERCLASS: "/ielts-preparation-online/masterclass?type=classic",
  ALL_PLUS_EXCLUSIVE:
    "/ielts-preparation-online/masterclass?type=PLUS_EXCLUSIVE",
  COUNSELLING_APP:
    "https://play.google.com/store/apps/details?id=com.leapscholar.app",
  IELTS_APP: "https://leapielts.app.link/open",
  JOIN_CLASS: "/profile/join/",
  IELTS_TEST: "/profile/ielts_test",
  SEO_PILLER_PAGE: "/exams/ielts",

  // TOFU Landing Pages
  USA_HOMEPAGE: "/usa",
  CANADA_HOMEPAGE: "/canada",
  AUSTRALIA_HOMEPAGE: "/australia",
  UK_HOMEPAGE: "/uk",
  IRELAND_HOMEPAGE: "/ireland",
  GERMANY_HOMEPAGE: "/germany",
  TOFU_VN_EXPERIMENT: "/spot-counselling/experiment-redirect",

  //Spot Counselling
  SPOT_COUNSELLING: "/spot-counselling",
  SPOT_COUNSELLING_CALENDER: "/spot-counselling/calender",
  SPOT_COUNSELLING_PAYMENT: "/spot-counselling/payment",
  SPOT_COUNSELLING_PAYMENT_CONFIRMATION:
    "/spot-counselling/payment-confirmation",
  //autoLS pages
  NEW_LIVEPAGE_AUTOLS: "/get-live-counselling",
  SC_PROFILE: "/profile/counselling/sc_profile",
  SPOT_COUNSELLING_ONBOARD: "/spot-counselling/onboard",
  AUTOLS_CONFIRMATION_PAGE: "/spot-counselling/autols-confirmation",
  MOCK_TEST_LANDING_PAGE: "/profile/ielts_plus/ielts_mock_test",
  IELTS_BATCHCLASS_SCHEDULE_PAGE: "/profile/ielts_plus/ielts_batch_schedule",
  PRACTISE_TAB: "/android_app/module",
  ANDROID_MOCK_TEST:
    "/profile/ielts_plus/app_mock?mock_test_type=event_mock_test",
  ANDROID_MOCK_TEST2: "https://leapielts.app.link/open/ielts_mock_test",
  ANDROID_VOICE_ROOMS: "https://leapielts.app.link/open/voiceroom",
  ANDROID_JOIN_BATCH_CLASS: "https://leapielts.app.link/open/join_class",
  ANDROID_IELTS_APP: "https://leapielts.app.link/open/",
  IOS_ZOOM_APP:
    "https://apps.apple.com/in/app/zoom-one-platform-to-connect/id546505307?utm_source=website&utm_medium=iOS&utm_campaign=dashboard",
  IELTS_MOCK_TEST_PAGE: getIeltsMockTestUrl("exam/IELTS"),
  IELTS__COUNSELLING_TAB: "/ielts-counselling-webview",
  PROFILE_COMMUNITY: "/profile/community",
  PROFILE_COMMUNITY_FEED: "/profile/community/feed",
  PROFILE_COMMUNITY_CREATE: "/profile/community/create_post",
  PROFILE_COMMUNITY_TOPICS: "/profile/community/all_topics",
  PROFILE_COMMUNITY_SEARCH: "/profile/community/search",
  PROFILE_COMMUNITY_PROFILE: "/profile/community/community_profile",
  PROFILE_COMMUNITY_TOPIC: "/profile/community/topic",
  PROFILE_COMMUNITY_POST_VIEW: "/profile/community/view",
  VIEW_ALL_IELTS_BATCHES: "/profile/ielts_plus/view_all",
  FREE_RESOURCE: "/profile/ielts_plus/free_resource",
  EXPIRED_CLASS: "/profile/ielts_plus/expired_class",
  IELTS_INTERMEDIATE_SCREEN: "/profile/ielts/",
  IELTS_ELITE_SLOTS_SCREEN: "/ielts-preparation-online/ielts-elite-demo",
  SPEAKING_EVALUATION: "/profile/speaking_evaluation",
  IELTS_COURSE_LIST_PAGE: "/ielts-preparation-online/courses",
  IELTS_DEMO_REWARDS_PAGE: "/profile/ielts/demo-rewards",
  ANDROID_DASHBOARD: "https://leapielts.app.link",
  ANDROID_MASTERCLASS: "https://leapielts.app.link/open/masterclass",
  IELTS_ONBOARDING_SCREEN: "/profile/onboarding",
  ANDROID_MOCKTEST: "https://leapielts.app.link/open/tabmocktests",
  IELTS_SELECT_INSTRUCTION_PDF:
    "https://ls.leapscholar.com/selectbatchusermanual",
  IELTS_ELITE_INSTRUCTION_PDF:
    "https://ls.leapscholar.com/Elitebatchusermanual",

  //Bofu Pages
  ONBOARDING_PAGE: "/onboarding", // currently not in use
  VO_CALENDAR_PAGE: "/profile/counselling/vo-calendar",
  UPDATE_PROFILE: "/profile/update-profile",
  DOCUMENTS_UPLOAD: "/profile/documents_upload",
  VISA_DOCUMENTS_UPLOAD: "/profile/visa-documents-upload",
  BOFU_APP_DOWNLOAD: "/AppDownload",
  IELTS_EXPIRED_CLASS_LIST: "/profile/ielts_plus/expired_class_list",
  IELTS_DEMO_FLOW_FOR_DISQUALIFIED_USERS:
    "/ielts-preparation-online/ielts-elite-demo/unlock",
  REDIRECT_PAGE: "/redirectPageV2",
  ME_USER_LOGOUT: "/api/ielts-dashboard/dashboard_logout",
  IELTS_LIY_MOCK_TEST: "/profile/ielts/liy-mocktest",
  COUSNELLOR_ASSIGNED_PAGE: "/profile/counselling/counsellor-assignment",
  redirectingPage: "/redirecting-page",
  SELF_PREP: "/profile/ielts/self-prep",
  LIY_LANDING_PAGE_WEBFLOW:
    "https://ielts.leapscholar.com/ielts-learn-it-yourself-launch",
  IELTS_INDIA_REFERAL_T_AND_C: "https://ls.leapscholar.com/referraltnc",
  IELTS_MENA_REFERAL_T_AND_C: "https://ls.leapscholar.com/refmenaielts",
  SAT_MENA_REFERAL_T_AND_C:
    "https://ls.leapscholar.com/sat-referral-terms-and-conditions-uae",
  SAT_SAUDI_REFERRAL_LINK:
    "https://ls.leapscholar.com/Sat-referral-terms-and-conditions",
  FLSE_FEEDBACK: "/test-prep/dashboard/flse/feedback",
  IELTS_MOCKTEST: "/test-prep/dashboard/ielts-mocktest",
  IELTS_REFERRAL: "/ielts-referral",
  SAT_REFERRAL: "/sat-referral",
  SAT_COUNSELLOR_WHATSAAP:
    "https://wa.me/971501469115?text=I%27m%20interested%20in%20your%20SAT%20Bootcamp%20",
}

export const handleDynamicQueryStrings = (
  dynamicParam: string,
  bundleName = "IELTS_PLUS",
): string => {
  return `/api/login?callbackUrl=${encodeURIComponent(
    `/profile?flowType=ielts&section=ieltsPlus&${dynamicParam}&bundle=${bundleName}`,
  )}`
}

export const getProfileIeltsPlus = (
  batchId: number,
  bundleName = "IELTS_PLUS",
): string => {
  return `/profile?flowType=ielts&section=ieltsPlus&batch=${batchId}&bundle=${bundleName}`
}

//****** all usa page routes - start *****//
const {
  USA_HOMEPAGE,
  FIND_UNIVERSITY,
  USA_MENTORS,
  USA_MENTOR_VIDEOS,
  USA_MENTOR_VIDEOS_VIDEO,
  COUNTRY_COLLEGE,
  COUNTRY_COLLEGE_ALLCOURSES,
  COUNTRY_COLLEGE_COURSE,
} = navRoutes
export const USAPageRoutes = [
  USA_HOMEPAGE,
  FIND_UNIVERSITY,
  USA_MENTORS,
  USA_MENTOR_VIDEOS,
  USA_MENTOR_VIDEOS_VIDEO,
  COUNTRY_COLLEGE,
  COUNTRY_COLLEGE_ALLCOURSES,
  COUNTRY_COLLEGE_COURSE,
]
//****** all usa page routes - end *****//

export const navbarLinks = {
  "Study Abroad": {
    text: "Study Abroad",
    links: [
      {
        text: "Study in USA",
        link: "/usa",
        isTargetBlank: false,
      },
      {
        text: "Study in UK",
        link: "/uk",
        isTargetBlank: false,
      },
      {
        text: "Study in Canada",
        link: "/canada",
        isTargetBlank: false,
      },
      {
        text: "Study in Australia",
        link: "/australia",
        isTargetBlank: false,
      },
      {
        text: "Study in Ireland",
        link: "/ireland",
        isTargetBlank: false,
      },
      {
        text: "Study in Germany",
        link: "/germany",
        isTargetBlank: false,
      },
      {
        text: "Our Counsellors",
        link: "/counsellors",
        isTargetBlank: false,
      },
      {
        text: "Master’s Programs",
        link: "https://advantage.leapscholar.com/",
        isTargetBlank: true,
      },
      {
        text: "Undergraduate Programs",
        link: "https://advantage.leapscholar.com/for-undergrad",
        isTargetBlank: true,
      },
    ],
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/study-abroad.svg`,
  },
  IELTS: {
    text: "IELTS",
    links: [
      {
        text: "IELTS Booking Details",
        link: null,
        breadcrumb: "Booking",
        nestedSubmenu: [
          { text: "IELTS Dates", link: "/exams/ielts/dates" },
          { text: "IELTS Fees", link: "/exams/ielts/fees" },
          { text: "IELTS Centres", link: "/exams/ielts/centre" },
          { text: "IELTS Registration", link: "/exams/ielts/registration" },
          { text: "IELTS Slot Booking", link: "/exams/ielts/slot-booking" },
        ],
      },
      {
        text: "IELTS Module Details",
        link: null,
        breadcrumb: "Module",
        nestedSubmenu: [
          { text: "IELTS Module Overview", link: "/exams/ielts/modules" },
          {
            text: "Speaking Details",
            link: "/exams/ielts/modules/speaking-overview",
          },
          {
            text: "Listening Details",
            link: "/exams/ielts/modules/listening-overview",
          },
          {
            text: "Writing Details",
            link: "/exams/ielts/modules/writing-overview",
          },
          {
            text: "Reading Details",
            link: "/exams/ielts/modules/reading-overview",
          },
        ],
      },
      {
        text: "IELTS Practice Test",
        link: "/exams/ielts/practice-test",
      },
      { text: "IELTS Exam Overview", link: "/exams/ielts" },
      {
        text: "Paid IELTS Courses",
        link: "/ielts-preparation-online",
        hideInLoggedInState: true,
      },
      {
        text: "Free IELTS Masterclass",
        link: "/ielts-free-online-coaching",
        hideInLoggedInState: true,
      },
      {
        text: "Important IELTS Articles",
        link: "/blog/category/ielts-preparation/",
        isTargetBlank: true,
      },
    ],
    icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/ielts.svg",
  },
  "Download App": {
    text: "Download App",
    links: [
      {
        text: "Counselling App",
        link: "https://play.google.com/store/apps/details?id=com.leapscholar.app",
        isTargetBlank: true,
      },
      {
        text: "IELTS Prep App",
        link: "https://leapielts.app.link/open",
        isTargetBlank: true,
      },
    ],
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/download-app.svg`,
  },
  Account: {
    text: "Account",
    links: [
      {
        text: "Profile",
        link: "/profile?type=navbar",
        isTargetBlank: false,
      },
      {
        text: "Log Out",
        link: "/api/logout",
        isTargetBlank: false,
      },
    ],
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg`,
  },
}

export const menaAccountLink = {
  Account: {
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg`,
    text: "Account",
    links: [
      {
        text: "Profile",
        link: navRoutes.IELTS_STUDENT_DASHBOARD,
        isTargetBlank: false,
      },
      {
        text: "Log Out",
        link: "/api/logout",
        isTargetBlank: false,
      },
    ],
  },
}

export const contactInfo = [
  {
    text: "(080) 47184844",
    link: "tel:+918047184844",
    icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/call.svg",
    contentName: "Phone",
  },
  {
    text: "contact@leapscholar.com",
    link: "mailto:contact@leapscholar.com",
    icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/email.svg",
    contentName: "Email",
  },
]

export const singleLinks = [
  {
    text: "Events",
    link: "/events",
    icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/event.svg",
  },
  {
    text: "Blogs",
    link: "/blog/",
    icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/library_books.svg",
  },
  {
    text: "About",
    link: "/about-us",
    icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/about.svg",
  },
]

export const loggenInStickyLinks = [
  {
    text: "Counselling Dashboard",
    link: "/profile",
  },
  {
    text: "IELTS Prep Dashboard",
    link: "/test-prep/dashboard/",
  },
]

export const menaLoggInStickyLinks = [
  {
    text: "IELTS Prep Dashboard",
    link: "/test-prep/dashboard/",
  },
]

export const ieltsContactInfo: Record<string, { text: string; link: string }> =
  {
    phone: {
      text: "080-47184844",
      link: "tel:+918047184844",
    },
    email: {
      text: "ielts.support@leapscholar.com",
      link: "mailto:ielts.support@leapscholar.com",
    },
  }
export const ieltsContactInfo2 = {
  text: " +91-08047184844",
  link: "tel:+91-08047184844",
}

export const ieltsFlowPages: RegExp[] = [
  /^\/mock-tests\/band-predictor-test\/[a-zA-Z0-9-]+$/,
  /^\/profile\/fast_track_registration$/,
  /\/ielts-referral/,
]
