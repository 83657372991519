import {
  CountryCode,
  countryData,
  validatePhoneNumberOnCountry,
} from "DataMapper/CountryCode"
import { eventCategory } from "DataMapper/EventTracker/EventData"
import { loginApis } from "components/Login/LoginApis"
import LoginPopup from "components/Login/LoginPopup"
import { LOGIN_STEPS, removeLeadingZeros } from "components/Login/utils"
import { spotCounsellingActions } from "components/SpotCounselling/redux/SpotCounsellingActions"
import { spotCounsellingApi } from "components/SpotCounselling/redux/SpotCounsellingApi"
import {
  getUserData,
  saveUserData,
} from "components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi"
import { authActions } from "components/decorator/redux/AuthActions"
import { fireTagEvent, logEvent, sendCustomGaEvent } from "ga"
import { generateUniqueValue } from "lib/DateUtils/dateLib/format"
import { useRouter } from "next/router"
import { navRoutes } from "page_routes"
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react"
import { connect, useDispatch } from "react-redux"
import { Action, Dispatch } from "redux"
import {
  trackClick,
  trackEvent,
  trackEventAsync,
  trackSignup,
} from "scripts/segment"
import { deleteCookie, getCookie, saveCookie } from "utils/cookie-utiils"
import { segmentEvents } from "utils/event_utils"
import { pageName } from "utils/events-utils"
import { getUtmData } from "utils/utm_utils"
import autoReadSMS from "utils/autoReadSMS"

const MAX_AGE = 60 * 60 * 24 * 365

export const webFlowType = {
  WEB_FLOW_MASTERCLASS: "webflow_masterclass",
  WEB_FLOW_FASTTRACK: "webflow_fasttrack",
}

export const getTrackingId = (url_string: string, key: string) => {
  try {
    const url = new URL(window.location.origin + url_string)
    return url.searchParams.get(key)
  } catch (_) {
    return
  }
}

export const getLoginPageTitle = (
  url: string,
  trackingId: string | null | undefined,
) => {
  const callbackUrl = url.split("?")[0]
  if (
    callbackUrl &&
    (callbackUrl === navRoutes.FAST_TRACK_FORM ||
      callbackUrl.startsWith(navRoutes.FAST_TRACK_VIDEO))
  ) {
    if (trackingId === webFlowType.WEB_FLOW_FASTTRACK) {
      return "Register for Fast Track"
    }
    return "Login for IELTS Fast Track"
  } else if (trackingId === webFlowType.WEB_FLOW_MASTERCLASS) {
    return "Register for Masterclass"
  } else if (url.includes("product?bundleName=IELTS_LIY_COURSE")) {
    return "Login to proceed"
  } else return "Login"
}

export interface IIvrMessage {
  type: "error" | "success" | "default"
  message: string
}

interface Props {
  isModalOpen: boolean
  title: string
  canClose: boolean
  preFilledPhone: string
  callback: Function
  flowType: string
  saveToken: Function
  canChangeCountryCode: boolean
  resetPreFilledPhone: () => void
  handleClose: () => void
  country: CountryCode
  setCountry: (country: CountryCode) => void
  ctaText?: string
  imageUrl?: string
}

const Login: FunctionComponent<Props> = ({
  isModalOpen,
  title,
  callback,
  canClose,
  preFilledPhone,
  flowType,
  saveToken,
  canChangeCountryCode,
  handleClose,
  resetPreFilledPhone,
  country,
  setCountry,
  ctaText,
  imageUrl,
}) => {
  const [phone, setPhone] = useState<string>("")
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const [activeView, setActiveView] = useState<string>("phone")
  const [correlationId, setCorrelationId] = useState<string>("")
  const whatsAppSubscription = true
  const [phoneText, setPhoneText] = useState<string>("Get OTP")
  const [phoneErrMsg, setPhoneErrMsg] = useState<string>("")
  const [otpText, setOtpText] = useState<string>("Proceed")
  const [otpErrMsg, setOtpErrMsg] = useState<string>("")
  const [ivrMsg, setIvrMsg] = useState<IIvrMessage>({
    type: "default",
    message: "",
  })
  const [phoneInfoMsg, setPhoneInfoMsg] = useState<string>("")
  const [otpSuccessMsg, setOtpSuccessMsg] = useState<string>("")
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showTruecaller, setShowTruecaller] = useState<boolean>(true)
  const phoneNumberWithoutZeros = removeLeadingZeros(phone)
  const dispatch = useDispatch()

  const router = useRouter()
  const { samesite } = router.query
  const path = router.pathname
  const utmDetails = getUtmData(null)
  const callbackUrl = router.query.callbackUrl as string
  const variant = getTrackingId(callbackUrl, "variant")
  const eventParam = useMemo(() => {
    if (callbackUrl) {
      const trackinId = getTrackingId(callbackUrl, "tracking_id")
      if (trackinId === webFlowType.WEB_FLOW_MASTERCLASS) {
        return {
          category: trackinId,
          label: "IELTS - MasterClass Landing Page",
        }
      } else if (trackinId === webFlowType.WEB_FLOW_FASTTRACK) {
        return {
          category: trackinId,
          label: "IELTS Fast Track",
        }
      } else return null
    }
    return null
  }, [callbackUrl])

  const handleGaEvent = (payload: any) => {
    const userProps = {
      lead_stage: payload.leadStage,
      user_id: payload.userId,
      subscribed_to_whatsapp: payload.subscribedToWhatsapp,
      login_type: payload.authType,
    }
    const eventProps = {
      page_name: payload.pageName,
      auth_type: payload.authType,
      subscribed_to_whatsapp: payload.subscribedToWhatsapp,
      login_type: payload.authType,
    }
    sendCustomGaEvent("Log In Successful", eventProps, userProps)
  }

  const autoUpdateOtp = (autoOtp: { code: string; error: string }) => {
    const { code } = autoOtp
    if (code && code.length == 6) {
      const optList = code.split("")
      return setOtp(optList)
    }
  }

  const generateOtp = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: "Login Modal",
      widgetFormat: "Modal",
      contentName: "Get OTP",
      contentFormat: "Button",
      phone: phone,
    })
    if (!validatePhoneNumberOnCountry(phoneNumberWithoutZeros, country)) {
      setPhoneErrMsg("Please enter a valid phone number")
      return
    } else {
      setPhoneErrMsg("")
      setPhoneText("Please wait")
      try {
        const res = await loginApis.generateOtp(
          countryData[country].code,
          country,
          phoneNumberWithoutZeros,
          whatsAppSubscription,
        )
        if (res.success) {
          setCorrelationId(res.data)
          setActiveView("otp")
          autoReadSMS(autoUpdateOtp)
          if (eventParam) {
            logEvent(eventParam.category, "Get OTP", eventParam.label)
          }
        } else {
          trackEventAsync(segmentEvents.OTP_ERROR, {
            phoneNumber: phoneNumberWithoutZeros,
            errorCode: res?.response?.status || 200,
            errorMessage: res?.message,
            currentPage: router.pathname,
            refererUrl: document?.referrer,
            loginStep: LOGIN_STEPS.GENERATE_OTP,
          })
          setPhoneErrMsg(res.message)
        }
      } catch (err: any) {
        setPhoneErrMsg("Something went wrong. Please try after sometime")
      } finally {
        setPhoneText(ctaText || "Get OTP")
      }
    }
  }

  const validateOtp = () => {
    return new Promise<void>(async (resolve) => {
      trackClick(pageName.Login_Modal, {
        widgetName: "Login Modal",
        widgetFormat: "Modal",
        contentName: "Submit OTP",
        contentFormat: "Button",
        phone: phoneNumberWithoutZeros,
      })
      setOtpErrMsg("")
      setOtpText("Please wait")
      try {
        const res = await loginApis.validateOtp(
          correlationId,
          otp.join(""),
          whatsAppSubscription,
          country,
        )
        if (res.success) {
          await storeToken(res.data, setOtpErrMsg)
          if (eventParam) {
            logEvent(eventParam.category, "Submit OTP", eventParam.label)
          }
        } else {
          trackEventAsync(segmentEvents.OTP_ERROR, {
            phoneNumber: phoneNumberWithoutZeros,
            errorCode: res?.response?.status || 200,
            errorMessage: res?.message,
            currentPage: router.pathname,
            refererUrl: document?.referrer,
            loginStep: LOGIN_STEPS.SUBMIT_OTP,
          })
          setOtpErrMsg(res.message)
        }
      } catch (err: any) {
        console.log(err.message, "err?")
        deleteCookie(null, "token")
        setOtpErrMsg("OTP is invalid")
      } finally {
        setOtpText("Proceed")
        resolve()
      }
    })
  }

  const storeToken = async (token: string, cbError: Function) => {
    try {
      saveCookie(null, "token", token, MAX_AGE, samesite as string)
      const registerRes = await loginApis.registerUser(flowType)
      if (registerRes.success) {
        const trackPayload = {
          pageName: path || "NA",
          authType: registerRes?.data?.newUser ? "Sign Up" : "Sign In",
          leadStage: registerRes?.data?.stage || "",
          utmCampaign: utmDetails?.utmCampaign || "",
          gclid: utmDetails?.gclid || "",
          adName: utmDetails?.adName || "",
          utmSource: utmDetails?.utmSource || "",
          utmTerm: utmDetails?.utmTerm || "",
          adId: utmDetails?.adId || "",
          flowType: flowType,
          userId: registerRes?.data?.userId || "",
          subscribedToWhatsapp: whatsAppSubscription,
          phone: phoneNumberWithoutZeros || "NA",
          country: country || "NA",
        }
        saveCookie(null, "user_id", registerRes?.data?.userId || "")
        saveToken(token)
        logEvent("login success", "login success", "")
        const webFlowData = localStorage.getItem("webFlowData")

        if (registerRes?.data?.newUser) {
          await fireTagEvent("complete registration")
          logEvent(eventCategory.NEW_IELTS_LEAD, router.asPath, "")
        }
        dispatch(spotCounsellingActions.fetchStage())
        const data = await getUserData()
        await trackSignup("Log In Successful", trackPayload)
        handleGaEvent(trackPayload)
        if (registerRes?.data?.newUser && data?.data?.data?.preferredCountry)
          await trackSignup(
            `Log In Successful ${data?.data?.data?.preferredCountry}`,
            trackPayload,
          )

        if (webFlowData && variant) {
          const reSavedata = await saveUserData(JSON.parse(webFlowData))
          if (reSavedata?.data?.success) {
            localStorage.removeItem("webFlowData")
            trackEvent("Force redirect webFlow failed", webFlowData)
            router.push(navRoutes.MEETING_FINDER)
          }
        } else {
          setTimeout(() => {
            callback(registerRes?.data?.newUser)
            handleClose()
          }, 1)
        }
      } else {
        trackEventAsync(segmentEvents.OTP_ERROR, {
          phoneNumber: phoneNumberWithoutZeros,
          errorCode: registerRes?.response?.status || 200,
          errorMessage: registerRes?.message,
          currentPage: router.pathname,
          refererUrl: document?.referrer,
          loginStep: LOGIN_STEPS.REGISTER_USER,
        })
        deleteCookie(null, "token")
        cbError(registerRes.message)
      }
    } catch (err) {
      console.error("Error in storeToken: ", err)
    }
  }

  const resendOtp = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: "Login Modal",
      widgetFormat: "Modal",
      contentName: "Resend OTP",
      contentFormat: "Button",
      phone: phoneNumberWithoutZeros,
    })
    try {
      const res = await loginApis.resendOtp(
        country,
        correlationId,
        whatsAppSubscription,
      )
      if (res.success) {
        setOtpSuccessMsg("OTP resent successfully")
        if (eventParam) {
          logEvent(eventParam.category, "Resend OTP", eventParam.label)
        }
      } else {
        trackEventAsync(segmentEvents.OTP_ERROR, {
          phoneNumber: phoneNumberWithoutZeros,
          errorCode: res?.response?.status || 200,
          errorMessage: res?.message,
          currentPage: router.pathname,
          refererUrl: document?.referrer,
          loginStep: LOGIN_STEPS.RESEND_OTP,
        })
        setOtpErrMsg(res.message)
      }
    } catch (err: any) {
      setOtpErrMsg("Something went wrong. Please try after sometime")
    } finally {
      setTimeout(() => {
        setOtpSuccessMsg("")
      }, 5000)
    }
  }

  const ivrCallRequest = async () => {
    try {
      const res = await loginApis.ivrCallRequest(
        country,
        correlationId,
        whatsAppSubscription,
      )
      if (res?.success) {
        setIvrMsg({ type: "success", message: "OTP call on the way." })
      } else {
        setIvrMsg({
          message: "Failed to sent OTP, please try after sometime",
          type: "error",
        })
      }
    } catch (e) {
      console.log(e)
      setIvrMsg({
        message: "Failed to sent OTP, please try after sometime",
        type: "error",
      })
    }
  }

  useEffect(() => {
    if (preFilledPhone) {
      setPhone(preFilledPhone)
    }
  }, [preFilledPhone])

  useEffect(() => {
    if (phoneNumberWithoutZeros && preFilledPhone) {
      generateOtp()
      resetPreFilledPhone()
    }
  }, [phoneNumberWithoutZeros])

  const truecallerDeepLink = (requestId: string) => {
    window.location.href = `truecallersdk://truesdk/web_verify?requestNonce=${requestId}&partnerKey=${process.env.PARTNER_KEY}&partnerName=${process.env.PARTNER_NAME}&title=${title}&&skipOption=Skip`
  }

  const truecallerRejectHandler = (error: string) => {
    setShowLoader(false)
    setShowTruecaller(false)
    setPhoneInfoMsg(error)
  }

  const truecallerErrorHandler = (error: string) => {
    setPhoneErrMsg(error)
    setShowLoader(false)
    setShowTruecaller(false)
  }

  const truecallerAcceptHandler = async (token: string) => {
    await storeToken(token, truecallerErrorHandler)
  }

  const truecallerDeepLinkActive = (requestId: string): Promise<string> =>
    new Promise((resolve, reject) => {
      let requestAttempt = 0
      const requestInterval = setInterval(async () => {
        const res = await loginApis.verifyTruecaller(
          requestId,
          whatsAppSubscription,
        )
        requestAttempt += 1
        if (res?.success) {
          if (res.data) {
            clearInterval(requestInterval)
            resolve(res.data)
          }
        } else {
          trackEventAsync(segmentEvents.OTP_ERROR, {
            phoneNumber: phoneNumberWithoutZeros,
            errorCode: res?.response?.status || 200,
            errorMessage: res?.message,
            currentPage: router.pathname,
            refererUrl: document?.referrer,
            loginStep: LOGIN_STEPS.VERFY_LOGIN_WITH_TC,
          })
        }
        if (requestAttempt > 5 || !res?.success) {
          clearInterval(requestInterval)
          reject(
            "Oops ! Truecaller verification failed. You can login with OTP",
          )
        }
      }, 3000)
    })

  const trueCallerCheckout = (requestId: string): Promise<string> =>
    new Promise((resolve, reject) => {
      setTimeout(async function () {
        if (document.hasFocus()) {
          reject(
            "Looks like you don’t have Truecaller installed. You can login with OTP",
          )
        } else {
          resolve(requestId)
        }
      }, 600)
    })

  const truecallerLoginManager = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: "Login Modal",
      widgetFormat: "Modal",
      contentName: "Login with Truecaller",
      contentFormat: "Button",
    })
    setShowLoader(true)
    const requestId = generateUniqueValue()
    if (requestId) {
      const res = await loginApis.initTruecallerLogin(requestId)
      if (!res?.success) {
        trackEventAsync(segmentEvents.OTP_ERROR, {
          phoneNumber: phoneNumberWithoutZeros,
          errorCode: res?.response?.status || 200,
          errorMessage: res?.message,
          currentPage: router.pathname,
          refererUrl: document?.referrer,
          loginStep: LOGIN_STEPS.LOGIN_WITH_TC,
        })
      }
      truecallerDeepLink(requestId)
      await trueCallerCheckout(requestId)
        .then(truecallerDeepLinkActive)
        .then(truecallerAcceptHandler)
        .catch(truecallerRejectHandler)
    }
  }

  return (
    <LoginPopup
      phone={phone}
      handlePhoneChange={(e: ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value)
      }}
      activeView={activeView}
      handleViewChange={(view: string) => setActiveView(view)}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      generateOtp={generateOtp}
      whatsAppSubscription={whatsAppSubscription}
      otp={otp}
      handleOtpChange={(val: Array<any>) => setOtp(val)}
      validateOtp={validateOtp}
      canClose={canClose}
      title={title}
      imageUrl={imageUrl}
      resendOtp={resendOtp}
      phoneText={ctaText || phoneText}
      phoneErrMsg={phoneErrMsg}
      otpText={otpText}
      otpErrMsg={otpErrMsg}
      otpSuccessMsg={otpSuccessMsg}
      truecallerLoginManager={truecallerLoginManager}
      showTruecaller={showTruecaller}
      showLoader={showLoader}
      phoneInfoMsg={phoneInfoMsg}
      canChangeCountryCode={canChangeCountryCode}
      country={country}
      changeCountry={(country) => setCountry(country)}
      ivrCallRequest={ivrCallRequest}
      ivrMsg={ivrMsg}
      setIvrMsg={setIvrMsg}
    />
  )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  saveToken: (payload: any) => {
    dispatch(authActions.saveAuth(payload))
  },
})

export default connect(null, mapDispatchToProps)(Login)
