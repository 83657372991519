import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import EnterOtpView from "components/Login/EnterOtpView"
import EnterPhoneView from "components/Login/EnterPhoneView"
import { CountryCode } from "DataMapper/CountryCode"
import { ChangeEvent, FunctionComponent, useEffect } from "react"
import { trackPage } from "scripts/segment"
import { pageName } from "utils/events-utils"
import { IIvrMessage } from "components/Login/index"

interface Props {
  isModalOpen: boolean
  title: string
  imageUrl?: string
  canClose: boolean
  activeView: string
  phone: string
  otp: Array<any>
  whatsAppSubscription: boolean
  phoneText: string
  phoneErrMsg: string
  otpText: string
  otpErrMsg: string
  otpSuccessMsg: string
  showTruecaller: boolean
  showLoader: boolean
  phoneInfoMsg: string
  canChangeCountryCode: boolean
  country: CountryCode
  handlePhoneChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOtpChange: (val: Array<any>) => void
  handleViewChange: (view: string) => void
  generateOtp: () => Promise<void>
  validateOtp: () => Promise<void>
  resendOtp: () => Promise<void>
  handleClose: () => void
  truecallerLoginManager: () => void
  changeCountry: (country: CountryCode) => void
  ivrCallRequest: () => void
  ivrMsg: IIvrMessage
  setIvrMsg: (value: IIvrMessage) => void
}

const LoginModal: FunctionComponent<Props> = ({
  isModalOpen,
  title,
  imageUrl,
  canClose,
  activeView,
  phone,
  otp,
  phoneText,
  otpText,
  phoneErrMsg,
  otpErrMsg,
  otpSuccessMsg,
  showTruecaller,
  showLoader,
  phoneInfoMsg,
  canChangeCountryCode,
  country,
  handleClose,
  handlePhoneChange,
  handleOtpChange,
  handleViewChange,
  generateOtp,
  validateOtp,
  resendOtp,
  truecallerLoginManager,
  changeCountry,
  ivrCallRequest,
  ivrMsg,
  setIvrMsg,
}) => {
  useEffect(() => {
    trackPage(pageName.Login_Modal)
  }, [])

  // const wrapperRef = useRef(null);

  // useEffect(() => {
  //   function handleClickOutside(event: any) {
  //     /*@ts-ignore*/
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       handleClose();
  //       console.log('clicked')
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [wrapperRef]);

  return (
    <Modal
      // ref={wrapperRef}
      className="flex justify-center items-end md:items-center md:px-4"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 800,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      {!showLoader ? (
        <Fade in={isModalOpen}>
          <div className="relative w-full flex items-center justify-center md:mx-auto">
            <div className="bg-white w-120 py-4 md:py-8 shadow-10 rounded-t-2xl md:rounded-6">
              {canClose && (
                <div
                  className="flex justify-end w-full px-8"
                  onClick={handleClose}
                >
                  <img
                    className="cursor-pointer"
                    src={`${process.env.CDN_URL}/assets/images/auth/close_icon.svg`}
                    alt="close"
                  />
                </div>
              )}
              <div className="flex flex-col items-center px-4 md:px-16 w-full">
                <div className="flex md:flex-col flex-col-reverse items-center w-full">
                  <p className="md:text-font22 text-lg font-bold text-center pt-5 md:pt-6 pb-2 md:pb-4">
                    {activeView === "phone"
                      ? title
                      : "Verify your Phone Number"}
                  </p>
                  <img
                    className="md:w-36 md:mb-8 mb-4 w-24"
                    src={
                      imageUrl ||
                      `${process.env.CDN_URL}/assets/images/auth/login_logo.png`
                    }
                    alt="leap"
                  />
                </div>

                {activeView === "phone" ? (
                  <EnterPhoneView
                    phone={phone}
                    phoneText={phoneText}
                    phoneErrMsg={phoneErrMsg}
                    handleChange={handlePhoneChange}
                    changeView={handleViewChange}
                    generateOtp={generateOtp}
                    truecallerLoginManager={truecallerLoginManager}
                    showTruecaller={showTruecaller}
                    phoneInfoMsg={phoneInfoMsg}
                    canChangeCountryCode={canChangeCountryCode}
                    country={country}
                    changeCountry={changeCountry}
                  />
                ) : (
                  <EnterOtpView
                    otp={otp}
                    phone={phone}
                    otpText={otpText}
                    otpErrMsg={otpErrMsg}
                    otpSuccessMsg={otpSuccessMsg}
                    handleChange={handleOtpChange}
                    changeView={handleViewChange}
                    submitOtp={validateOtp}
                    resendOtp={resendOtp}
                    country={country}
                    ivrCallRequest={ivrCallRequest}
                    ivrMsg={ivrMsg}
                    setIvrMsg={setIvrMsg}
                  />
                )}
                <hr className="bg-black opacity-25 w-full my-4" />
                <p className="md:text-sm text-xs opacity-50 text-center">
                By submitting this form, you consent to our{" "}
                  <a
                    className="underline"
                    href="https://leapfinance.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of service
                  </a>{" "}
                  and{" "}
                  <a
                    className="underline"
                    href="https://leapfinance.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy policy
                  </a>{" "}
                  and to be contacted by us via Call/Email/WhatsApp/SMS
                </p>
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <div
          className="h-64 flex flex-col justify-center items-center"
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`${process.env.CDN_URL}/assets/images/circle_loader.gif`}
            alt="Loading..."
            className="w-16 md:w-20"
          />
        </div>
      )}
    </Modal>
  )
}

export default LoginModal
