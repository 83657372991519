import { parseCookies, setCookie, destroyCookie } from "nookies"
import { deleteCookie } from "utils/cookie-utiils"
import { cookiesConstant } from "../DataMapper/Common/cookiesConstant"

const TOKEN_NAME = "token"
const MAX_AGE = 60 * 60 * 24 * 365 // 365 days
const USER_ID = "user_id"
const ANALYTICS_USER_ID = "ajs_user_id"
const SESSION_TOKEN = "sessiontoken"

export function setTokenCookie(ctx, token) {
  setCookie(ctx, TOKEN_NAME, token, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: process.env.NODE_ENV === "production",
    path: "/",
    sameSite: "lax",
  })
}

export function removeTokenCookie(ctx) {
  destroyCookie(ctx, TOKEN_NAME, {
    maxAge: -1,
    path: "/",
  })
  destroyCookie(ctx, USER_ID, {
    maxAge: -1,
    path: "/",
  })
  deleteCookie(ctx, "platform")
  deleteCookie(ctx, "mefu_user")
  destroyCookie(ctx, ANALYTICS_USER_ID, {
    maxAge: -1,
    path: "/",
  })
  destroyCookie(ctx, SESSION_TOKEN, {
    maxAge: -1,
    path: "/",
  })
  destroyCookie(ctx, cookiesConstant.IP_COUNTRY_KEY)
}

export function getTokenCookie(ctx) {
  const cookies = parseCookies(ctx)
  return cookies.token
}
